var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"title":"Company","icon":"mdi-profile"}},[_vm._v(" not a correct page "),(_vm.formModel)?_c('smart-form',{attrs:{"schema":_vm.formSchema,"form-code":"company-detail"},model:{value:(_vm.formModel),callback:function ($$v) {_vm.formModel=$$v},expression:"formModel"}}):_vm._e(),_c('toolbar',{attrs:{"left-items":[
            { id: 'save', onClick: _vm.saveFormData, },

        ],"right-items":[
            {
                id: 'done',
                onClick: _vm.toListView,

            },
        ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }