<template>
    <Page title="Company" icon="mdi-profile">
        not a correct page
        <smart-form
            v-if="formModel"
            v-model="formModel"
            :schema="formSchema"
            form-code="company-detail"
        />
        <toolbar
            :left-items="[
                { id: 'save', onClick: saveFormData, },

            ]"
            :right-items="[
                {
                    id: 'done',
                    onClick: toListView,

                },
            ]"
        />
    </Page>
</template>
<script>
import api from '@/api';

const formSchema = {
    companyName: {
        component: 'text-field',
        label: 'User Code',
        cols: 4,
    },
    abn: {
        component: 'text-field',
        label: 'Full Name',
        cols: 4,
    },
    accountsCompanyId: {
        component: 'text-field',
        label: 'Accounts Company Id',
        cols: 8,
    },
    companyCode: {
        component: 'text-field',
        label: 'Company Code',
        cols: 3,
    },
    code: {
        component: 'text-field',
        label: 'Code',
        cols: 3,
    },

    name: {
        component: 'text-field',
        label: 'Name',
        cols: 4,
    },


    // favoriteColor: {
    //     component: 'RadioField',
    //     label: 'Favourite Colour',
    //     items: [
    //         { text: 'Blue', value: 'blue' },
    //         { text: 'Red', value: 'red' },
    //         { text: 'Green', value: 'green' },
    //     ],
    //     cols: 4,
    // },

};


export default {
    name: 'CompanyDetail',
    data() {
        return {
            formModel: null,
            formSchema,
        };
    },
    methods: {
        async getFormData() {
            const id = this.$route.params.id
            const result = await api.get(this.$store, `company/${id}`);
            this.formModel = { ...result.data };
            // console.log('getForm', this.formModel);
        },
        logForm() {
            console.log(JSON.stringify(this.formModel, null, 2));
        },
        async saveFormData() {
            // console.log('saveForm', this.formModel);
            const result = await api.post(this.$store, 'company', this.formModel);
            this.formModel = result.data;
        },
        fakeDelete(val) {
            console.log('fakedelete', val);
        },
        toListView() {
            this.$router.push('/auth/admin/company');
        },
    },
    async mounted() {
        await this.getFormData();
    },
};
</script>
<!--
       <toolbar
            :left-buttons="[
                { text: 'Click Something', name: 'something', onClick: logForm, className: 'primary' },
                {
                    text: 'Delete Something',
                    name: 'deletesomething',
                    onClick: fakeDelete,
                    className: 'warning',
                    confirm: 'Are you sure you want to delete?',
                },
                {
                    text: 'Delete Another',
                    name: 'another',
                    onClick: fakeDelete,
                    className: 'error',
                    confirm: 'Are you sure you want to delete another?',
                },
            ]"
            :right-buttons="[
                { text: 'Done', name: 'done', onClick: logForm, className: 'primary' },

            ]"
        />
-->
